import { useState } from 'react';

function Square({ value, onSquareClick }) {
  return (
    <button className="square" onClick={ onSquareClick }>{ value }</button>
  )
}

function calculateWinner(squares) {
  // check rows and columns
  for(let i=0; i<3; i++) {
    // console.log(i)
    // console.log(3*i, 3*i+1, 3*i+2);
    // console.log(i, i+3, i+6)
    if(squares[3*i] && squares[3*i] === squares[3*i+1] && squares[3*i] === squares[3*i+2])
      return squares[3*i];
    if(squares[i] && squares[i] === squares[i+3] && squares[i] === squares[i+6])
      return squares[i];
  }
  // check diagonals
  for(let i=0; i<=2; i+=2) {
    // console.log(i)
    // console.log(i, 4, 8-i)
    if(squares[i] && squares[i] === squares[4] && squares[i] === squares[8-i])
      return squares[i];
  }
  return null;
}

function Board({ xIsNext, squares, onPlay }) {

  function handleClick(i) {
    if(squares[i] || calculateWinner(squares))
      return;
    const nextSquares = squares.slice();
    if(xIsNext)
      nextSquares[i] = 'X';
    else
      nextSquares[i] = 'O';
    onPlay(nextSquares);
  }

  const winner = calculateWinner(squares);
  let status;
  if(winner)
    status = 'Winner: ' + winner;
  else
    status = 'Next player: ' + (xIsNext ? 'X' : 'O');


  return (
    <>
      <div className="status">{ status }</div>
      <div className="board-row">
        <Square value={ squares[0] } onSquareClick={ ()=> handleClick(0) } />
        <Square value={ squares[1] } onSquareClick={ ()=> handleClick(1) } />
        <Square value={ squares[2] } onSquareClick={ ()=> handleClick(2) } />
      </div>
      <div className="board-row">
        <Square value={ squares[3] } onSquareClick={ ()=> handleClick(3) } />
        <Square value={ squares[4] } onSquareClick={ ()=> handleClick(4) } />
        <Square value={ squares[5] } onSquareClick={ ()=> handleClick(5) } />
      </div>
      <div className="board-row">
        <Square value={ squares[6] } onSquareClick={ ()=> handleClick(6) } />
        <Square value={ squares[7] } onSquareClick={ ()=> handleClick(7) } />
        <Square value={ squares[8] } onSquareClick={ ()=> handleClick(8) } />
      </div>
    </>
  );
}

export default function Game() {
  const [history, setHistory] = useState([Array(9).fill(null)]);
  const [currentMove, setCurrentMove] = useState(0);
  const xIsNext = currentMove % 2 === 0;
  const currentSquares = history[currentMove];

  function handlePlay(nextSquares) {
    const nextHistory = [...history.slice(0, currentMove + 1), nextSquares]
    setHistory(nextHistory);
    setCurrentMove(nextHistory.length - 1);
  }

  function jumpTo(step) {
    setCurrentMove(step);
  }

  const moves = history.map((squares, move) => {
    let description;
    if(move > 0)
      description = 'Go to move #' + move;
    else
      description = 'Go to game start';

    return (
      <li key={ move }>
        <button onClick={ ()=> jumpTo(move) }>{ description }</button>
      </li>
    )
  })

  return (
    <div className="game">
      <div className="game-board">
        <Board xIsNext={ xIsNext } squares={ currentSquares } onPlay={ handlePlay }/>
      </div>
      <div className="game-info">
        <ol>{ moves }</ol>
      </div>
    </div>
  )
}